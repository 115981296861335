import { CommonModule } from "@modules/common_module/CommonModule";
import { MODULE_STATUS } from "@modules/common_module/ModulesStatusesProvider/types";
import { filter, take, tap } from "rxjs";

import { SDK_MODULES } from "../constants";
import { COMMON_SDK_EVENTS } from "./constants";

class CommonSdkEvents {
    constructor(private readonly commonModule: CommonModule) {}

    public readonly WIDGET_CLOSED = COMMON_SDK_EVENTS.WIDGET_CLOSED;
    public readonly WIDGET_INJECTED = COMMON_SDK_EVENTS.WIDGET_INJECTED;
    public readonly WIDGET_OPENED = COMMON_SDK_EVENTS.WIDGET_OPENED;

    public runIfModuleReady(
        moduleName: SDK_MODULES,
        callback: () => void
    ): void {
        const modulesStatusesProvider =
            this.commonModule.exports.modulesStatusesProvider;

        modulesStatusesProvider
            .selectModuleStatus(moduleName)
            .pipe(
                filter((status) => status === MODULE_STATUS.INITIALIZED),
                take(1),
                tap(() => callback())
            )
            .subscribe();
    }

    /**
     * TODO: Remove deprecated properties after all usages are replaced with `runIfModuleReady` method
     */

    /**
     * @deprecated Use `runIfModuleReady` method instead of constants
     */
    public readonly REVIEWS_SDK_READY = COMMON_SDK_EVENTS.REVIEWS_SDK_READY;
    public readonly REWARDS_SDK_READY = COMMON_SDK_EVENTS.REWARDS_SDK_READY;
    public readonly WISHLISTS_SDK_READY = COMMON_SDK_EVENTS.WISHLISTS_SDK_READY;

    /**
     * @deprecated Deprecated property names used from enum values
     */
    public readonly [COMMON_SDK_EVENTS.WIDGET_CLOSED] =
        COMMON_SDK_EVENTS.WIDGET_CLOSED;
    public readonly [COMMON_SDK_EVENTS.WIDGET_INJECTED] =
        COMMON_SDK_EVENTS.WIDGET_INJECTED;
    public readonly [COMMON_SDK_EVENTS.WIDGET_OPENED] =
        COMMON_SDK_EVENTS.WIDGET_OPENED;
    public readonly [COMMON_SDK_EVENTS.REVIEWS_SDK_READY] =
        COMMON_SDK_EVENTS.REVIEWS_SDK_READY;
    public readonly [COMMON_SDK_EVENTS.REWARDS_SDK_READY] =
        COMMON_SDK_EVENTS.REWARDS_SDK_READY;
    public readonly [COMMON_SDK_EVENTS.WISHLISTS_SDK_READY] =
        COMMON_SDK_EVENTS.WISHLISTS_SDK_READY;
}

export default CommonSdkEvents;
