import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { getJsonFromScript } from "@lib/utils";
import { create, literal, type, union } from "superstruct";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { loginApplicationLoggerToken } from "../../apps/login/tokens";
import CustomerInfoEntity from "./CustomerInfoEntity";
import { CustomerInfoProviderInterface } from "./CustomerInfoProviderInterface";
import { CustomerInfoSchema } from "./CustomerInfoSchema";

const AdditionalInfoSchema = type({
    GW_CUSTOMER_INFO: union([CustomerInfoSchema, literal(null)]),
});

@singleton()
class CustomerInfoFromJsonProvider implements CustomerInfoProviderInterface {
    private customerInfo: CustomerInfoEntity | null = null;

    constructor(
        @inject(loginApplicationLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    readData(): void {
        if (this.customerInfo) return;
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );
        const additionalInfo = create(additionalInfoData, AdditionalInfoSchema);
        this.customerInfo = additionalInfo.GW_CUSTOMER_INFO;
        this.logger.info("customer info provider is ready", {
            customerInfo: this.customerInfo,
        });
    }

    getEmail(): string | null {
        return this.customerInfo?.email || null;
    }

    getId(): number | null {
        return this.customerInfo?.id || null;
    }

    getIsB2b(): boolean {
        return this.customerInfo?.isB2b || false;
    }
}

export default CustomerInfoFromJsonProvider;
