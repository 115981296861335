import { globalLoggerToken } from "@/tokens";
import { container, instanceCachingFactory, singleton } from "tsyringe";

import { ModuleInterface } from "@interfaces/ModuleInterface";

import { DesignModeProviderInterface } from "./DesignModeProvider/DesignModeProviderInterface";
import { FromShopifyObjectDesignModeProvider } from "./DesignModeProvider/FromShopifyObjectDesignModeProvider";
import DesignModeQueryParamsProvider from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProvider";
import DesignModeQueryParamsProviderInterface from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProviderInterface";
import {
    locationControllerToken,
    referrerProviderToken,
    shopifyUrlUtilsToken,
} from "./di_tokens";
import LocationController from "./LocationController/LocationController";
import LocationControllerInterface from "./LocationController/LocationControllerInterface";
import ModulesStatusesProvider from "./ModulesStatusesProvider/ModulesStatusesProvider";
import ReferrerProvider, {
    ReferrerProviderInterface,
} from "./ReferrerProvider";
import { ShopifyUrlUtils } from "./ShopifyUrlUtils/ShopifyUrlUtils";

interface CommonModuleExports {
    locationController: LocationControllerInterface;
    shopifyUrlUtils: ShopifyUrlUtils;
    referrerProvider: ReferrerProviderInterface;
    modulesStatusesProvider: ModulesStatusesProvider;
    designModeProvider: DesignModeProviderInterface;
    designModeQueryParamsProvider: DesignModeQueryParamsProviderInterface;
}

@singleton()
export class CommonModule implements ModuleInterface<CommonModuleExports> {
    exports: CommonModuleExports;

    public constructor() {
        const commonModuleLogger = container
            .resolve(globalLoggerToken)
            .newLogger("CommonModule");

        container.register(locationControllerToken, {
            useFactory: instanceCachingFactory(() => {
                return new LocationController(window.location);
            }),
        });

        container.register(shopifyUrlUtilsToken, {
            useFactory: instanceCachingFactory(() => {
                return new ShopifyUrlUtils(window.Shopify?.routes?.root || "/");
            }),
        });

        container.register(referrerProviderToken, {
            useFactory: instanceCachingFactory(() => {
                return new ReferrerProvider();
            }),
        });

        const modulesStatusesProvider = new ModulesStatusesProvider(
            commonModuleLogger
        );

        const designModeProvider = new FromShopifyObjectDesignModeProvider();

        const designModeQueryParamsProvider = new DesignModeQueryParamsProvider(
            designModeProvider,
            container.resolve(locationControllerToken)
        );

        this.exports = {
            locationController: container.resolve(locationControllerToken),
            shopifyUrlUtils: container.resolve(shopifyUrlUtilsToken),
            referrerProvider: container.resolve(referrerProviderToken),
            modulesStatusesProvider,
            designModeProvider,
            designModeQueryParamsProvider,
        };
    }

    public registerProviders() {
        return {};
    }
}
