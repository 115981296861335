import { globalLoggerToken } from "@/tokens";
import { JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import {
    TokensResponse,
    TokensResponseSchema,
} from "@modules/authentication/schemas/TokensResponseSchema";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { create } from "superstruct";
import { inject, injectable } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { refreshTokenServiceApiClientToken } from "../di_tokens";
import RefreshTokenServiceInterface from "../TokenManager/RefreshTokenServiceInterface";

@injectable()
export class RefreshTokenApiService implements RefreshTokenServiceInterface {
    public constructor(
        @inject(refreshTokenServiceApiClientToken)
        private readonly apiClient: ApiClientInterface,
        @inject(globalLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    refreshToken(params: { currentToken: string }): Observable<TokensResponse> {
        return this.apiClient
            .post({
                url: "/auth/refresh",
                responseType: "json",
                contentType: JsonMimeType,
                body: JSON.stringify({
                    currentToken: params.currentToken,
                }),
            })
            .pipe(
                map((response) => {
                    return create(response.body, TokensResponseSchema);
                })
            );
    }
}
