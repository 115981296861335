import TokenManagerInterface from "@modules/token_manager/TokenManager/TokenManagerInterface";
import { map } from "rxjs";
import { singleton } from "tsyringe";

import { ROLE } from "../constants";
import { AuthenticationInfoProviderInterface } from "./AuthenticationInfoProviderInterface";

@singleton()
export class AuthenticationInfoProvider
    implements AuthenticationInfoProviderInterface
{
    public constructor(private readonly tokenManager: TokenManagerInterface) {}

    public selectRole() {
        return this.tokenManager.updates.pipe(
            map(() =>
                this.tokenManager.isCustomer()
                    ? ROLE.Customer
                    : this.tokenManager.isGuest()
                    ? ROLE.Guest
                    : null
            )
        );
    }
}
