import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import { from, lastValueFrom, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { singleton } from "tsyringe";

import { AuthApiService } from "../api_services/AuthApiService/AuthApiService";

@singleton()
export class ProxyAuthenticator {
    public constructor(
        private readonly authApiService: AuthApiService,
        private readonly tokenManagerModule: TokenManagerModule
    ) {}

    async authenticate() {
        const tokenManager = this.tokenManagerModule.exports.tokenManager;
        await lastValueFrom(
            (tokenManager.hasToken() && tokenManager.tokenIsExpired()
                ? from(tokenManager.refreshToken())
                : of(true)
            ).pipe(
                switchMap((isTokenReadyOrNotExists) => {
                    if (!isTokenReadyOrNotExists) {
                        tokenManager.discardToken();
                        throw new Error("Something went wrong");
                    }
                    return this.authApiService
                        .authWithProxy({
                            token: tokenManager.getToken(),
                        })
                        .pipe(
                            tap((response) => {
                                tokenManager.setToken(response.token);
                                tokenManager.setIsInitialized();
                            })
                        );
                })
            )
        );
    }
}
