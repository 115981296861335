class SdkAnalyticsEventsFactory {
    constructor(
        private readonly userAgent: string,
        private readonly location: string,
        private readonly app: string
    ) {}

    private getHeaders() {
        return {
            app: this.app,
            userAgent: this.userAgent,
            location: this.location,
            dateTime: new Date(),
        };
    }

    public createSdkStarted() {
        return {
            type: "SDK.Started",
            headers: this.getHeaders(),
            payload: {},
        };
    }

    public createSdkModuleRegistered(moduleName: string) {
        return {
            type: "SDK.ModuleRegistered",
            headers: this.getHeaders(),
            payload: {
                moduleName,
            },
        };
    }

    public createEventSubscribed(eventType: string) {
        return {
            type: "SDK.EventSubscribed",
            headers: this.getHeaders(),
            payload: {
                eventType,
            },
        };
    }

    public createEventUnsubscribed(eventType: string) {
        return {
            type: "SDK.EventUnsubscribed",
            headers: this.getHeaders(),
            payload: {
                eventType,
            },
        };
    }

    public createEventDispatched(eventType: string) {
        return {
            type: "SDK.EventDispatched",
            headers: this.getHeaders(),
            payload: {
                eventType,
            },
        };
    }

    public createMethodSubscribed(methodName: string) {
        return {
            type: "SDK.MethodSubscribed",
            headers: this.getHeaders(),
            payload: {
                methodName,
            },
        };
    }

    public createMethodUnsubscribed(methodName: string) {
        return {
            type: "SDK.MethodUnsubscribed",
            headers: this.getHeaders(),
            payload: {
                methodName,
            },
        };
    }

    public createMethodCalled(methodName: string) {
        return {
            type: "SDK.MethodCalled",
            headers: this.getHeaders(),
            payload: {
                methodName,
            },
        };
    }
}

export default SdkAnalyticsEventsFactory;
