import { CustomerInfoProviderInterface } from "@lib/CustomerInfoProvider/CustomerInfoProviderInterface";
import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { customerInfoProviderToken, globalLoggerToken } from "../../tokens";
import { ROLE } from "./constants";

@singleton()
export class CurrentTokenChecker {
    public constructor(
        @inject(customerInfoProviderToken)
        private readonly customerInfoProvider: CustomerInfoProviderInterface,
        private readonly tokenManagerModule: TokenManagerModule,
        @inject(globalLoggerToken) private readonly logger: LoggerInterface
    ) {}
    public check() {
        // Reset JWT after logout
        if (
            !this.customerInfoProvider.getId() &&
            this.tokenManagerModule.exports.tokenManager.getTokenPayload()?.data
                .role === ROLE.Customer
        ) {
            this.logger.info("Reset token, after loguout");
            this.tokenManagerModule.exports.tokenManager.discardToken();
        }

        // Reset JWT if shopifyCustomerId in JWT not equal customerId from customerInfoProvider(liquid)
        if (
            this.tokenManagerModule.exports.tokenManager.getTokenPayload()?.data
                .shopifyCustomerId &&
            this.tokenManagerModule.exports.tokenManager.getTokenPayload()?.data
                .shopifyCustomerId !== this.customerInfoProvider.getId()
        ) {
            this.logger.info(
                "Reset token, because the token was created for another customer"
            );
            this.tokenManagerModule.exports.tokenManager.discardToken();
        }
    }
}
